<template>
  <section class="not-found">
    <b-row style="width:100%">
      <b-col sm="6" class="mx-auto">
        <div class="img-wrap ratio-100">
          <div class="img-content">
            <b-img
              fluid
              :src="require('@/assets/imgs/illustrations/404.svg')"
              :alt="$t('page_not_found')"
            />
          </div>
        </div>
        <h3 class="mt-5">{{ $t("page_not_found") }}</h3>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {};
</script>
